
import generatePassword from 'password-generator'
import _ from 'lodash'

const config = {
  MIN_LENGTH: {
    rule: 10,
    message: 'Password must be at least 10 characters long'
  },
  MAX_LENGTH: {
    rule: 15,
    message: 'Password must be no more than 15 characters long'
  },
  UPPERCASE_MIN_COUNT: {
    rule: 1,
    message: 'Password must include at least 1 uppercase letter'
  },
  LOWERCASE_MIN_COUNT: {
    rule: 1,
    message: 'Password must include at least 1 lowercase letter'
  },
  NUMBER_MIN_COUNT: {
    rule: 1,
    message: 'Password must include at least 1 number'
  },
  // SPECIAL_CHAR_MIN_COUNT: {
  //   rule: 1,
  //   message: 'Password must at least 1 special character',
  // },
  // NON_REPEATING: {
  //   message: 'Password must have no repeating characters',
  // },
  BLACKLIST: {
    rule: [
      '234567',
      '765432',
      '345678',
      '876543',
      '456789',
      '987654',
      '543210',
      '012345',
      'chanel',
      'lenahc',
      '123456',
      'password',
      '12345678',
      'qwerty',
      '123456789',
      '12345',
      '1234',
      '111111',
      '1234567',
      'dragon',
      '123123',
      'baseball',
      'abc123',
      'football',
      'monkey',
      'letmein',
      '696969',
      'shadow',
      'master',
      '666666',
      'qwertyuiop',
      '123321',
      'mustang',
      '1234567890',
      'michael',
      '654321',
      'pussy',
      'superman',
      '1qaz2wsx',
      '7777777',
      'fuckyou',
      '121212',
      '000000',
      'qazwsx',
      '123qwe',
      'killer',
      'trustno1',
      'jordan',
      'jennifer',
      'zxcvbnm',
      'asdfgh',
      'hunter',
      'buster',
      'soccer',
      'harley',
      'batman',
      'andrew',
      'tigger',
      'sunshine',
      'iloveyou',
      'fuckme',
      '2000',
      'charlie',
      'robert',
      'thomas',
      'hockey',
      'ranger',
      'daniel',
      'starwars',
      'klaster',
      '112233',
      'george',
      'asshole',
      'computer',
      'michelle',
      'jessica',
      'pepper',
      '1111',
      'zxcvbn',
      '555555',
      '11111111',
      '131313',
      'freedom',
      '777777',
      'pass',
      'fuck',
      'maggie',
      '159753',
      'aaaaaa',
      'ginger',
      'princess',
      'joshua',
      'cheese',
      'amanda',
      'summer',
      'love',
      'ashley',
      '6969',
      'nicole',
      'chelsea',
      'biteme',
      'matthew',
      'access',
      'yankees',
      '987654321',
      'dallas',
      'austin',
      'thunder',
      'taylor',
      'matrix'
    ],
    message: 'Password is not secure enough'
  }
}

const _applyMinLengthRule = (password, rule) => {
  return password && password.length >= rule
}
const _applyMaxLengthRule = (password, rule) => {
  return password && password.length <= rule
}
const _applyUppercaseMinCountRule = (password, rule) => {
  const uppercaseCount = password.match(/([A-Z])/g)
  return uppercaseCount && uppercaseCount.length >= rule
}
const _applyLowercaseMinCountRule = (password, rule) => {
  const lowercaseCount = password.match(/([a-z])/g)
  return lowercaseCount && lowercaseCount.length >= rule
}
const _applyNumberMinCountRule = (password, rule) => {
  const numberCount = password.match(/([\d])/g)
  return numberCount && numberCount.length >= rule
}
const _applySpecialCharMinCountRule = (password, rule) => {
  const specialCharCount = password.match(/([\!\?\-])/g)
  return specialCharCount && specialCharCount.length >= rule
}
const _applyNonRepeatingRule = (password, rule) => {
  const repeatingCharacters = password.match(/(.)\1+/g)
  return !repeatingCharacters
}
const _applyBlacklistRule = (password, rule) => {
  const isBlacklisted = _.includes(rule, password)
  return !isBlacklisted
}

const applyPolicyRule = (options) => {
  switch (options.policyRuleName) {
    case 'MIN_LENGTH':
      return _applyMinLengthRule(options.password, options.policyRule.rule)
    case 'MAX_LENGTH':
      return _applyMaxLengthRule(options.password, options.policyRule.rule)
    case 'UPPERCASE_MIN_COUNT':
      return _applyUppercaseMinCountRule(options.password, options.policyRule.rule)
    case 'LOWERCASE_MIN_COUNT':
      return _applyLowercaseMinCountRule(options.password, options.policyRule.rule)
    case 'NUMBER_MIN_COUNT':
      return _applyNumberMinCountRule(options.password, options.policyRule.rule)
    case 'SPECIAL_CHAR_MIN_COUNT':
      return _applySpecialCharMinCountRule(options.password, options.policyRule.rule)
    case 'NON_REPEATING':
      return _applyNonRepeatingRule(options.password, options.policyRule.rule)
    case 'BLACKLIST':
      return _applyBlacklistRule(options.password, options.policyRule.rule)
    default:
      return new Error('Unknown policy rule')
  }
}

const generateTempPassword = () => {
  const maxLength = _.get(config, 'MAX_LENGTH.rule', 36)
  const minLength = _.get(config, 'MIN_LENGTH.rule', 1)
  const randomLength =
    Math.floor(Math.random() * (maxLength - minLength)) + minLength
  var password = ''
  var validateResult = validatePassword(password)
  while (_.isError(validateResult)) {
    password = generatePassword(randomLength, false, /[\w\d\?\-]/)
    validateResult = validatePassword(password)
  }
  return password
}

export const validatePassword = (password) => {
  const isValid = _.chain(config)
    .map((policyRule, policyRuleName) => {
      return {
        policyRule: policyRule,
        policyRuleName: policyRuleName
      }
    })
    .reduce((memo, next) => {
      const policyRule = next.policyRule
      const policyRuleName = next.policyRuleName
      if (!_.isError(memo)) {
        const ruleMatches =
          applyPolicyRule({
            policyRuleName: policyRuleName,
            policyRule: policyRule,
            password: password
          })
        return ruleMatches || new Error(policyRule.message)
      } else {
        return memo
      }
    }, true)
    .value()
  return isValid
}

