import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as validators from '../../../validators'
import Form from '../../../components/Form'
import { Input } from '../../../components/Fields'

const ResetPasswordForm = Form('reset-password')

const ErrorContainer = styled.div`
  height: 20px;
  text-align: center;
  color: ${({ theme }) => theme.error};
` 

const ResetPasswordFormWrapper = ({
  onSubmit,
  errorMessage
}) => {
  return (
    <>
      <ErrorContainer>{errorMessage}</ErrorContainer>
      <ResetPasswordForm onSubmit={onSubmit}>
        <Input
          label={'New password'}
          name='password'
          type='password'
          validate={[validators.password]}
        />
        <Input
          label={'Confirm new password'}
          name='passwordConfirmation'
          type='password'
          validate={[validators.passwordConfirmation]}
        />
      </ResetPasswordForm>
    </>
  )
}

ResetPasswordFormWrapper.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
}

export default ResetPasswordFormWrapper
