import React from 'react'
import ReactDOM from 'react-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'

import ResetPassword from './containers/ResetPassword'
import Unsubscribe from './containers/Unsubscribe'
import FlagInappropriate from './containers/FlagInappropriate'

import createStore from './store/createStore'

const store = createStore()

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#DC582A',
    },
    secondary: {
      main: '#012169',
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route key='reset-password' path='/reset-password' component={ResetPassword} />
          <Route key='unsubscribe' path='/unsubscribe' component={Unsubscribe} />
          <Route key='flag-message' path='/flag-message' component={FlagInappropriate} />
        </Switch>
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);