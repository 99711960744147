import React, { useState, useEffect } from 'react'
import qs from 'qs'
import _ from 'lodash'

import assets from '../../assets'
import P from '../../components/P'
import sdk from '../../digitalStoreSdk'

import style from './style'

const invalidTokenError = new Error('Invalid token.')

const Unsubscribe = (props) => {
  const [status, setStatus] = useState('LOADING')

  const getQueryParams = () => {
    const queryString = _.get(props, 'location.search', '').slice(1)
    const { customerId, token } = qs.parse(queryString)
    return { customerId, token }
  }

  const validateToken = () => {
    const { customerId, token } = getQueryParams()
    console.log({ customerId, token })

    return sdk.customers
      .validateCustomerToken({ token })
      .then(tokenResult => {
        if (
          tokenResult.customerId === customerId &&
          tokenResult.tokenType === 'unsubscribe'
        ) {
          return tokenResult
        } else {
          throw new Error()
        }
      })
      .catch(error => {
        console.error(error)
        throw invalidTokenError
      })
  }

  const unsubscribe = () => {
    const { customerId, token } = getQueryParams()
    const updates = {
      emailMarketing: false
    }

    return sdk.customers
      .unsubscribe({ token, customerId, updates })
  }

  useEffect(() => {
    // on mount:
    // check that token is valid
    // if it is, unsubscribe
    // if anything goes wrong, show error message
    validateToken()
      .then(() => {
        return unsubscribe()
          .then(() => {
            setStatus('UNSUBSCRIBED')
          })
      })
      .catch(error => {
        if (error === invalidTokenError) {
          setStatus('INVALID_TOKEN')
        } else {
          setStatus('ERROR')
        }
      })
  }, [])

  const getMessage = (status) => ({
    'LOADING': 'Loading...',
    'UNSUBSCRIBED': 'Successfully unsubscribed.',
    'INVALID_TOKEN': 'Invalid unsubscribe token. Did you already use this link?',
    'ERROR': 'Something went wrong.',
  })[status]

  return (
    <div style={style.container}>
      <div style={style.logoContainer}>
        <img src={assets.logo} alt={`logo`} style={style.logo} />
      </div>
      <div style={style.messageContainer}>
        <P value={getMessage(status)} />
      </div>
    </div>
  )
}

export default Unsubscribe
