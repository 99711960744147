import React from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'
import style from './style'
import { SubmissionError } from 'redux-form'

const Form = ({
    submitLabel = 'Submit',
    handleSubmit,
    onSubmit,
    pristine,
    reset,
    submitting,
    invalid,
    children,
    error,
    ...props
}) => {
  const submit = handleSubmit((...args) => {
    const submitResult = onSubmit(...args)
    if (submitResult && submitResult.then) {
      return submitResult
        .catch(error => {
          throw new SubmissionError({ _error: error.message })
        })
    } else {
      return submitResult
    }
  })
  return (
    <form onSubmit={submit}>
      <div style={style.generalError}>{error}</div>
      <div style={style.formBody}>{children}</div>
      <div style={style.buttonContainer}>
        <Button
          label={!submitting && submitLabel}
          icon={submitting && <CircularProgress size={23} thickness={2} />}
          disabled={invalid || submitting}
          type='submit'
          fullWidth
          color="primary"
          variant="contained"
        >
          {submitLabel}
        </Button>
      </div>
    </form>
  )
}

Form.propTypes = {
  submitLabel: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Form
